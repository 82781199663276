import { DefaultTheme } from 'styled-components';

interface Theme extends DefaultTheme {
  colors: {
    neonBlue: string;
    electricPurple: string;
    vividRed: string;
    neonPink: string;
    electricGreen: string;
    brightYellow: string;
    neonCyan: string;
    deepBlack: string;
    brightWhite: string;
    background: string;
    text: string;
    primary: string;
    secondary: string;
    accent: string;
    error: string;
    success: string;
    warning: string;
    info: string;
    border: string;
    errorBackground: string;
    primaryLight: string;
    disabled: string;
    buttonBackground: string;
    buttonText: string;
    buttonHoverBackground: string;
    buttonHoverText: string;
    focus: string;
    primaryDark: string;
    overlay: string;
    textSecondary: string;  // Ensure this is not optional
    cardBackground: string; // Ensure this is not optional
    navBackground: string;  // Ensure this is not optional
  };
  fontSizes: {
    small: string;
    medium: string;
    large: string;
    xlarge: string;
    xxlarge: string;
    '5xl': string;
    xl: string;
    lg: string;
    md: string;
    sm: string;
  };
  spacing: {
    small: string;
    medium: string;
    large: string;
    xlarge: string;
  };
  borderRadius: {
    small: string;
    medium: string;
    large: string;
    md: string;
    default: string;
  };
  boxShadow: {
    md: string;
  };
  breakpoints: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  fontWeights: {
    light: number;
    normal: number;
    bold: number;
  };
  zIndex: {
    modal: number;
  };
  fonts: {
    primary: string;
    secondary: string;
  };
  lineHeights: {
    normal: number;
    heading: number;
    body: number;
  };
  shadows: {
    small: string;
    medium: string;
    large: string;
  };
  transitions: {
    default: string;
    fast: string;
  };
  sizes: {
    maxWidth: string;
    maxHeight: string;
  };
}

const lightTheme: Theme = {
  colors: {
    neonBlue: '#00f',
    electricPurple: '#800080',
    vividRed: '#f00',
    neonPink: '#ff69b4',
    electricGreen: '#00ff00',
    brightYellow: '#ffff00',
    neonCyan: '#00ffff',
    deepBlack: '#000000',
    brightWhite: '#ffffff',
    background: '#f0f0f0',
    text: '#333333',
    primary: '#FF4500',
    secondary: '#1C1C1C',
    accent: '#8A2BE2',
    error: '#dc3545',
    success: '#28a745',
    warning: '#ffc107',
    info: '#00FFFF',
    border: '#D3D3D3',
    errorBackground: '#f8d7da',
    primaryLight: '#FF6347',
    disabled: '#e0e0e0',
    buttonBackground: '#FF4500',
    buttonText: '#ffffff',
    buttonHoverBackground: '#FF6347',
    buttonHoverText: '#ffffff',
    focus: '#FF4500',
    primaryDark: '#B22222',
    overlay: 'rgba(0, 0, 0, 0.5)',
    textSecondary: '#6c757d',  // Ensure this is defined
    cardBackground: '#ffffff', // Ensure this is defined
    navBackground: '#343a40',  // Ensure this is defined
  },
  fontSizes: {
    small: '0.875rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
    '5xl': '3rem',
    xl: '1.5rem',
    lg: '1.25rem',
    md: '1rem',
    sm: '0.875rem',
  },
  spacing: {
    small: '0.25rem',
    medium: '0.5rem',
    large: '0.75rem',
    xlarge: '1rem',
  },
  borderRadius: {
    small: '0.2rem',
    medium: '0.3rem',
    large: '0.5rem',
    md: '0.375rem',
    default: '0.25rem',
  },
  boxShadow: {
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 700,
  },
  zIndex: {
    modal: 1050,
  },
  fonts: {
    primary: 'Montserrat, sans-serif',
    secondary: 'Open Sans, sans-serif',
  },
  lineHeights: {
    normal: 1.5,
    heading: 1.2,
    body: 1.7,
  },
  shadows: {
    small: '0 1px 2px rgba(0, 0, 0, 0.1)',
    medium: '0 3px 6px rgba(0, 0, 0, 0.1)',
    large: '0 10px 20px rgba(0, 0, 0, 0.2)',
  },
  transitions: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
  sizes: {
    maxWidth: '1200px',
    maxHeight: '100vh',
  },
};

const darkTheme: Theme = {
  // Copy the lightTheme and adjust values for dark theme
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    background: '#1a1a1a',
    text: '#e0e0e0',
    textSecondary: '#b8b8b8',
    cardBackground: '#252525',
    navBackground: '#212121',
  },
};

const megapromptTheme: Theme = {
  // Copy the lightTheme and adjust values for megaprompt theme
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    primary: '#800080',
    secondary: '#ff69b4',
    background: '#1c1e24',
    text: '#ffffff',
    textSecondary: '#a0a0a0',
    cardBackground: '#2a2e36',
    navBackground: '#3c4043',
  },
};

const highContrastTheme: Theme = {
  // Copy the lightTheme and adjust values for high contrast theme
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    background: '#000000',
    text: '#ffffff',
    textSecondary: '#d0d0d0',
    cardBackground: '#1a1a1a',
    navBackground: '#1e1e1e',
  },
};

export { darkTheme, highContrastTheme, lightTheme, megapromptTheme };
export type { Theme };

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./shadcn/button";
import { useTheme } from '../contexts/ThemeProvider';
import { ModeToggle } from './mode-toggle';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { themeMode } = useTheme();

  return (
    <nav className="sticky top-0 z-50">
      <div className="bg-background/80 backdrop-blur-sm border-b border-border shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-2xl font-bold text-primary hover:text-primary-light transition-colors">MEGAPROMPT.IO</Link>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <NavLink to="/">Home</NavLink>
                <NavLink to="/prompt-lab">Prompt Lab</NavLink>
                <NavLink to="/projects">Projects</NavLink>
                <NavLink to="/gallery">Gallery</NavLink>
                <NavLink to="/about">About</NavLink>
              </div>
            </div>
            <div className="hidden md:flex items-center">
              <ModeToggle />
              <Button asChild variant="outline" size="sm" className="ml-4">
                <Link to="/contact">Contact Us</Link>
              </Button>
            </div>
            <div className="md:hidden flex items-center">
              <ModeToggle />
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="ml-4 inline-flex items-center justify-center p-2 rounded-md text-primary hover:text-primary-light hover:bg-accent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded={isOpen}
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink to="/" mobile>Home</NavLink>
              <NavLink to="/prompt-lab" mobile>Prompt Lab</NavLink>
              <NavLink to="/projects" mobile>Projects</NavLink>
              <NavLink to="/gallery" mobile>Gallery</NavLink>
              <NavLink to="/about" mobile>About</NavLink>
              <NavLink to="/contact" mobile>Contact Us</NavLink>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

const NavLink: React.FC<{ to: string; children: React.ReactNode; mobile?: boolean }> = ({ to, children, mobile }) => (
  <Link
    to={to}
    className={`${
      mobile
        ? 'block px-3 py-2 rounded-md text-base font-medium'
        : 'px-3 py-2 rounded-md text-sm font-medium'
    } text-primary hover:text-primary-light hover:bg-accent transition-colors`}
  >
    {children}
  </Link>
);

export default Navbar;
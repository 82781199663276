import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-background/80 backdrop-blur-sm z-50" aria-live="polite" role="status">
      <div className="text-center">
        <div className="w-16 h-16 border-t-4 border-primary border-solid rounded-full animate-spin mb-4"></div>
        <p className="text-lg font-semibold text-foreground">Loading...</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
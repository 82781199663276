import React, { useState, useCallback, memo } from 'react';
import { useTheme } from '../../contexts/ThemeProvider'; // Updated import path
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Facebook, GitHub, Instagram, Linkedin, Twitter } from 'react-feather';

const FooterContainer = styled.footer`
  background-color: ${props => props.theme?.colors?.background || '#fff'};
  color: ${props => props.theme?.colors?.text || '#000'};
  padding: 3rem 0;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${props => props.theme?.colors?.primary || '#333'};
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialIcon = styled.a`
  color: ${props => props.theme?.colors?.text || '#000'};
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme?.colors?.primary || '#333'};
  }
`;

const FooterLink = styled(Link)`
  color: ${props => props.theme?.colors?.text || '#000'};
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme?.colors?.primary || '#333'};
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const NewsletterInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme?.colors?.border || '#ccc'};
  border-radius: 4px;
  background-color: ${props => props.theme?.colors?.background || '#fff'};
  color: ${props => props.theme?.colors?.text || '#000'};
`;

const NewsletterButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme?.colors?.primary || '#007bff'};
  color: ${props => props.theme?.colors?.background || '#fff'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme?.colors?.primaryHover || '#0056b3'};
  }

  &:disabled {
    background-color: ${props => props.theme?.colors?.disabled || '#d6d6d6'};
    cursor: not-allowed;
  }
`;

const AlertMessage = styled.p<{ status: 'success' | 'error' }>`
  color: ${props => props.status === 'success' ? props.theme?.colors?.success || 'green' : props.theme?.colors?.error || 'red'};
  margin-top: 0.5rem;
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid ${props => props.theme?.colors?.border || '#ccc'};
  color: ${props => props.theme?.colors?.textMuted || '#6c757d'};
`;

const Footer: React.FC = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleNewsletterSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubscriptionStatus('idle');

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Newsletter signup:', email);
      setSubscriptionStatus('success');
      setEmail('');
    } catch (error) {
      console.error('Newsletter signup failed:', error);
      setSubscriptionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  }, [email]);

  return (
    <FooterContainer theme={theme}>
      <FooterContent>
        <FooterSection>
          <FooterTitle>MEGAPROMPT.IO</FooterTitle>
          <p>Empowering Your Creativity, One Prompt at a Time</p>
          <SocialLinks>
            <SocialIcon href="https://www.facebook.com/profile.php?id=61564309958869" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <Facebook aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://x.com/MEGAPROMPTER" target="_blank" rel="noopener noreferrer" aria-label="X">
              <Twitter aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://instagram.com/megaprompter/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <Instagram aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://www.linkedin.com/company/megaprompt" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <Linkedin aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://github.com/ExpressionsBot" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
              <GitHub aria-hidden="true" />
            </SocialIcon>
          </SocialLinks>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Quick Links</FooterTitle>
          <nav aria-label="Footer Navigation">
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/projects">Projects</FooterLink>
            <FooterLink to="/gallery">Gallery</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
          </nav>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Legal</FooterTitle>
          <nav aria-label="Legal Navigation">
            <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
            <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
          </nav>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Newsletter</FooterTitle>
          <p>Stay updated with our latest projects and innovations.</p>
          <NewsletterForm onSubmit={handleNewsletterSubmit}>
            <NewsletterInput
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email for newsletter"
              disabled={isSubmitting}
            />
            <NewsletterButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Subscribing...' : 'Subscribe'}
            </NewsletterButton>
          </NewsletterForm>
          {subscriptionStatus === 'success' && (
            <AlertMessage role="alert" status="success">Successfully subscribed!</AlertMessage>
          )}
          {subscriptionStatus === 'error' && (
            <AlertMessage role="alert" status="error">Subscription failed. Please try again.</AlertMessage>
          )}
        </FooterSection>
      </FooterContent>
      <Copyright>
        © {new Date().getFullYear()} MEGAPROMPT.IO. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default memo(Footer);
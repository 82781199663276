import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStateProvider } from './contexts/GlobalStateContext';
import { ProjectProvider } from './contexts/ProjectContext';
import { ThemeProvider } from './contexts/ThemeProvider';
import Navbar from './components/Navbar';
import Footer from './components/common/Footer';
import ErrorBoundary from './components/common/ErrorBoundary';
import LoadingScreen from './components/common/LoadingScreen';
import ErrorFallback from './components/common/ErrorFallback';
import GlobalStyle from './styles/GlobalStyle';
import AnimatedRoutes from './components/AnimatedRoutes';
import ContactSection from './components/ContactSection';
import BackgroundCanvas from './components/common/BackgroundCanvas'; // Updated import

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <ThemeProvider>
          <GlobalStateProvider>
            <ProjectProvider>
              <GlobalStyle />
              <Router>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <div className="flex flex-col min-h-screen relative">
                    <BackgroundCanvas intensity="medium" />
                    <Navbar />
                    <main className="flex-grow relative z-10" aria-label="Main application content">
                      <Suspense fallback={<LoadingScreen />}>
                        <AnimatedRoutes />
                      </Suspense>
                    </main>
                    <Footer />
                  </div>
                </ErrorBoundary>
              </Router>
            </ProjectProvider>
          </GlobalStateProvider>
        </ThemeProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

export default App;
import React, { lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LoadingScreen from './common/LoadingScreen';

// Lazy load your page components
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'));
const AboutSection = lazy(() => import('./pages/AboutSection'));
const ProjectsSection = lazy(() => import('./pages/ProjectsSection'));
const GalleryPage = lazy(() => import('./pages/GalleryPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ContactSection = lazy(() => import('./ContactSection'));
const PromptLabPage = lazy(() => import('./pages/PromptLabPage'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsOfService = lazy(() => import('./TermsOfService'));

// Lazy load project components
const MTARFrameworkVisualization = lazy(() => import('./projects/mtar/MTARFrameworkVisualization'));
const NeuralNetworkVisualization = lazy(() => import('./projects/NeuralNetworkVisualization'));
const MidjourneyMasterclass = lazy(() => import('./projects/MidjourneyMasterClass'));
const SalesCompanionPage = lazy(() => import('./projects/SalesCompanionPage'));

const AnimatedRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<LoadingScreen />}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutSection />} />
          <Route path="/projects" element={<ProjectsSection />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/contact" element={<ContactSection />} />
          <Route path="/prompt-lab" element={<PromptLabPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/projects/mtar-framework-visualization" element={<MTARFrameworkVisualization />} />
          <Route path="/projects/neural-network-visualization" element={<NeuralNetworkVisualization />} />
          <Route path="/projects/midjourney-masterclass" element={<MidjourneyMasterclass />} />
          <Route path="/projects/sales-companion-chatbot" element={<SalesCompanionPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
import React, { Component, ReactNode } from 'react';
import { FaExclamationTriangle, FaSync } from 'react-icons/fa';
import * as Sentry from "@sentry/react";
import { Button } from "../shadcn/button";

interface Props {
  children: ReactNode;
  fallbackUI?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): Partial<State> {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ errorInfo });
    console.error('Uncaught error:', error, errorInfo);
    Sentry.captureException(error, { extra: errorInfo });
  }

  private handleRefresh = (): void => {
    window.location.reload();
  };

  private handleReportError = (): void => {
    const { error, errorInfo } = this.state;
    if (error) {
      Sentry.captureException(error, { extra: errorInfo || undefined });
      console.log('Error reported to Sentry:', error);
    }
  };

  private renderErrorMessage(): React.ReactNode {
    const { error, errorInfo } = this.state;
    if (process.env.NODE_ENV === 'development' && error) {
      return (
        <>
          <p className="text-lg mb-4">{error.toString()}</p>
          {errorInfo && (
            <details className="whitespace-pre-wrap mb-4">
              {errorInfo.componentStack}
            </details>
          )}
        </>
      );
    }
    return (
      <p className="text-lg mb-4">
        We apologize for the inconvenience. An unexpected error has occurred. Please try refreshing the page or contact our support team if the issue persists.
      </p>
    );
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.props.fallbackUI) {
        return this.props.fallbackUI;
      }
      return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center bg-background text-foreground" role="alert">
          <FaExclamationTriangle className="text-6xl text-destructive mb-4" aria-hidden="true" />
          <h1 className="text-3xl font-bold mb-4">Oops! Something went wrong.</h1>
          {this.renderErrorMessage()}
          <div className="space-x-4">
            <Button onClick={this.handleRefresh} aria-label="Refresh page">
              <FaSync className="mr-2" aria-hidden="true" />
              Refresh Page
            </Button>
            <Button onClick={this.handleReportError} aria-label="Report error">
              <FaSync className="mr-2" aria-hidden="true" />
              Report Error
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

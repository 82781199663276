import React from 'react';
import { Moon, Sun, Zap, Eye } from 'lucide-react';
import styled from 'styled-components';
import { useTheme } from '../hooks/useTheme';
import { Button } from './shadcn/button';

const ToggleButton = styled(Button)`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.text};
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export function ModeToggle() {
  const { themeMode, cycleTheme } = useTheme();

  const getIcon = () => {
    switch (themeMode) {
      case 'light':
        return <Sun className="h-[1.2rem] w-[1.2rem]" />;
      case 'dark':
        return <Moon className="h-[1.2rem] w-[1.2rem]" />;
      case 'megaprompt':
        return <Zap className="h-[1.2rem] w-[1.2rem]" />;
      case 'highContrast':
        return <Eye className="h-[1.2rem] w-[1.2rem]" />;
      default:
        return <Sun className="h-[1.2rem] w-[1.2rem]" />;
    }
  };

  return (
    <ToggleButton onClick={cycleTheme} variant="ghost" size="sm" aria-label="Toggle theme">
      {getIcon()}
    </ToggleButton>
  );
}
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { darkTheme, lightTheme, megapromptTheme, highContrastTheme, Theme } from '../styles/theme';

export type ThemeMode = 'light' | 'dark' | 'megaprompt' | 'highContrast';
export type FontSize = 'small' | 'medium' | 'large' | 'x-large';

export interface ThemeContextType {
  themeMode: ThemeMode;
  cycleTheme: () => void;
  setThemeMode: (mode: ThemeMode) => void;
  setMegapromptTheme: () => void;
  theme: Theme;
  fontSize: FontSize;
  setFontSize: (size: FontSize) => void;
  isReducedMotion: boolean;
  toggleReducedMotion: () => void;
}

const detectSystemPreference = (): ThemeMode => {
  if (typeof window !== 'undefined') {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
    if (window.matchMedia && window.matchMedia('(prefers-contrast: more)').matches) {
      return 'highContrast';
    }
  }
  return 'light';
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(() => {
    if (typeof window !== 'undefined') {
      const storedTheme = localStorage.getItem('theme') as ThemeMode | null;
      return (storedTheme === 'light' || storedTheme === 'dark' || storedTheme === 'megaprompt' || storedTheme === 'highContrast') ? storedTheme : detectSystemPreference();
    }
    return 'light';
  });

  const [fontSize, setFontSize] = useState<FontSize>(() => {
    if (typeof window !== 'undefined') {
      return (localStorage.getItem('fontSize') as FontSize) || 'medium';
    }
    return 'medium';
  });

  const [isReducedMotion, setIsReducedMotion] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('reducedMotion') === 'true';
    }
    return false;
  });

  const announceChange = useCallback((message: string) => {
    if (typeof document !== 'undefined') {
      const announcement = document.createElement('div');
      announcement.setAttribute('aria-live', 'polite');
      announcement.className = 'sr-only';
      announcement.textContent = message;
      document.body.appendChild(announcement);
      setTimeout(() => document.body.removeChild(announcement), 1000);
    }
  }, []);

  const announceThemeChange = useCallback((theme: ThemeMode) => {
    announceChange(`Theme changed to ${theme}`);
  }, [announceChange]);

  const announceFontSizeChange = useCallback((size: FontSize) => {
    announceChange(`Font size changed to ${size}`);
  }, [announceChange]);

  const announceReducedMotionChange = useCallback((isReduced: boolean) => {
    announceChange(`Reduced motion ${isReduced ? 'enabled' : 'disabled'}`);
  }, [announceChange]);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      localStorage.setItem('theme', themeMode);
      document.documentElement.classList.remove('light', 'dark', 'megaprompt', 'high-contrast');
      document.documentElement.classList.add(themeMode);
      announceThemeChange(themeMode);
    }
  }, [themeMode, announceThemeChange]);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      localStorage.setItem('fontSize', fontSize);
      document.documentElement.classList.remove('text-sm', 'text-base', 'text-lg', 'text-xl');
      document.documentElement.classList.add(getFontSizeClass(fontSize));
      announceFontSizeChange(fontSize);
    }
  }, [fontSize, announceFontSizeChange]);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      localStorage.setItem('reducedMotion', isReducedMotion.toString());
      document.documentElement.classList.toggle('motion-reduce', isReducedMotion);
      announceReducedMotionChange(isReducedMotion);
    }
  }, [isReducedMotion, announceReducedMotionChange]);

  const cycleTheme = useCallback(() => {
    setThemeMode((prevTheme) => {
      switch (prevTheme) {
        case 'light': return 'dark';
        case 'dark': return 'megaprompt';
        case 'megaprompt': return 'highContrast';
        case 'highContrast': return 'light';
        default: return 'light';
      }
    });
  }, []);

  const setMegapromptTheme = useCallback(() => {
    setThemeMode('megaprompt');
  }, []);

  const toggleReducedMotion = useCallback(() => {
    setIsReducedMotion(prev => !prev);
  }, []);

  const theme = useMemo(() => {
    switch (themeMode) {
      case 'dark': return darkTheme;
      case 'megaprompt': return megapromptTheme;
      case 'highContrast': return highContrastTheme;
      case 'light':
      default: return lightTheme;
    }
  }, [themeMode]);

  const contextValue = useMemo<ThemeContextType>(() => ({
    themeMode,
    cycleTheme,
    setThemeMode,
    setMegapromptTheme,
    theme,
    fontSize,
    setFontSize,
    isReducedMotion,
    toggleReducedMotion
  }), [themeMode, cycleTheme, setMegapromptTheme, theme, fontSize, setFontSize, isReducedMotion, toggleReducedMotion]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <StyledThemeProvider theme={theme}>
        <div className={`min-h-screen ${themeMode} ${getFontSizeClass(fontSize)}`}>
          {children}
        </div>
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

const getFontSizeClass = (size: FontSize): string => {
  switch (size) {
    case 'small': return 'text-sm';
    case 'medium': return 'text-base';
    case 'large': return 'text-lg';
    case 'x-large': return 'text-xl';
    default: return 'text-base';
  }
};

// No duplicate exports here
export { ThemeContext };
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../utils/cn";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-fiery-orange text-deep-charcoal hover:bg-fiery-orange/90",
        destructive: "bg-vivid-red text-white hover:bg-vivid-red/90",
        outline: "border border-electric-purple bg-deep-charcoal text-cool-gray hover:bg-electric-purple hover:text-deep-charcoal",
        secondary: "bg-electric-purple text-cool-gray hover:bg-electric-purple/80",
        ghost: "text-cool-gray hover:bg-fiery-orange/10 hover:text-fiery-orange",
        link: "text-bright-cyan underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          "font-montserrat font-bold shadow-neon-glow"
        )}
        ref={ref}
        aria-label={props['aria-label'] || "Button"}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
